const moment = require("moment/moment");

document.addEventListener('DOMContentLoaded', () => {
  $('.help-text').tooltip({
    template: '<div class="tooltip" role="tooltip">\
                <div class="tooltip-arrow"></div>\
                <div class="tooltip-inner help-text-tooltip"></div>\
               </div>'
  });

  render_employees_table();
  initialize_custom_month_range($('#custom_month_range_picker_template'), '#custom_month_range_picker_template');
  initialize_custom_month_range($('.pf_history_month_range'), '.pf_history_month_range');
  display_employee_analytics();
});

const render_employees_table = () => {
  $('#employee-table').DataTable({
    fixedHeader: true,
    pageLength: 100,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/employees',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#employee-table').DataTable().page.info().page) + 1
        data.per_page = $('#employee-table').DataTable().page.info().length
        data.search = { text: data.search.value, business_unit: $('#select-business-unit-filter').val(), user_type: $('#select-user-type-filter').val(), filter: $('#select-employees-status').val() }
        data.order = $('#employee-table').DataTable().order()[0][0]
        data.direction = $('#employee-table').DataTable().order()[0][1]
      }
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': 1,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('cursor-pointer employee-row')
          $(td).attr('onClick', `window.open('/employees/${rowData[7]}', '_blank')`)
        }
      },
      {
        'targets': 6,
        "bSortable": false
      }
    ]
  });
  debounceSearch('#employee-table')
}

const render_employee_pf_history_table = (employee_id) => {
  let scrollPosition = $(window).scrollTop();
  let table_id = '#employee-provident-fund-table';
  $(table_id).DataTable().destroy();
  $(table_id).DataTable({
    fixedHeader: true,
    pageLength: 10,
    processing: true,
    serverSide: true,
    searching: false,
    ajax: {
      'type': 'get',
      'url': `/employees/${employee_id}/show_pf_history`,
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($(table_id).DataTable().page.info().page) + 1
        data.per_page = $(table_id).DataTable().page.info().length
        data.order = $(table_id).DataTable().order()[0][0]
        data.direction = $(table_id).DataTable().order()[0][1]
        data.from = $("#pf_history_from").val();
        data.to = $("#pf_history_till").val();
      }
    },
    "drawCallback": () => {
      if ($(table_id).DataTable().rows().count()) {
        $('#employee-provident-fund-table tr:last').attr('id', 'pf-history-net-total-row');
      }
    },
    deferRender: true,
    columnDefs: [
      {
        targets: '_all',
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('text-center')
        }
      },
      {
        targets: 3,
        bSortable: false
      }
    ]
  });
  $(window).scrollTop(scrollPosition);
  debounceSearch(table_id);
}

document.addEventListener("DOMContentLoaded", () => {
  $('.salary-edit-modal').on('hidden.bs.modal', () => {
    document.getElementById('salary-edit-form').reset();
  });
});

$(document).on('keyup', (e) => {
  if(e.key == 'Escape') {
    $('#employee_designations').modal('hide')
  }
});

$(document).on('click', '#clear-pkr-limit-btn', (e) => {
  $(e.target).closest('form').find("input[type='text'").val('')
})

$(document).on('change', '#select-employees-status, #select-user-type-filter, #select-business-unit-filter', (e) => {
  $('#employee-table').DataTable().destroy();
  render_employees_table();
});

$(document).on('ajax:error ajax:success', '#salary-edit-form', (e) => {
  const { errors, success, url, warning_message } = e.detail[0];
  if (errors) {
    append_errors(errors, 'Cannot Add due to following issues:', $('.salary_history_ajax_errors'))
    removeErrorAndWarningMessage('.salary_modal', $('.salary_history_ajax_errors'))
  }
  if(warning_message) {
    displayWarningMessage(warning_message, $('.salary_history_warning_message'))
    removeErrorAndWarningMessage('.salary_modal', $('.salary_history_warning_message'))
  }
  if (success) {
    displaySuccessMessage(success, $('.salary_modal'))
    if ($('.merge_request').val() == 'true') {
      $('.merge_request').val(false)
    }
  }
  refresh_salaries_table(url);

});

$(document).on('ajax:error ajax:success', '#pkr-limit-form', (e) => {
  const { errors, success, html, warning_message } = e.detail[0];
  if (errors) {
    append_errors(errors, 'Cannot Add due to following issues:', $('.ajax_errors'))
    removeErrorAndWarningMessage('#pkr-limit-form', $('.ajax_errors'))
  }
  if(warning_message) {
    displayWarningMessage(warning_message, $('.warning_message'));
    removeErrorAndWarningMessage('#pkr-limit-form', $('.warning_message'))
  }
  if (success) {
    displaySuccessMessage(success, $('#add_pkr_limit'))
    $('#add_pkr_limit').html(html)
  }
});

$(document).on('click', '#provident-fund-tab', (e) => {
  let employee_id = e.target.closest('a').getAttribute('employee_id');
  render_employee_pf_history_table(employee_id);
})

const refresh_salaries_table = (url) =>{
  const employee_id = url.split('/')[2]
  $.ajax({
    type: 'GET',
    url: url,
    data: { employee_id: employee_id},
    dataType: 'JSON',
    success(response) {
      if (response.html !== ' ') {
        $('#salary-history-table').DataTable().clear()
        $('#salary-history-table').find('tbody').html(response.html)
      }
      $(`#employee-pkr-limit-${response.employee_id}`).val(response.pkr_limit)
    }
  });
}

const append_errors = (errors, message, error_message_div) => {
  const header_message = `<p class='text-danger px-4 pt-4'> <span class='fas fa-times-circle fa-lg mx-2'>\
                            </span> <strong>${message}</strong>\
                            <button class='close' type='button' id='close-errors-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                            </button> \
                          </p>`;
  error_message_div.removeClass('d-none')
  error_message_div.html(header_message);
  for (let error of Array.from(errors)) {
    const error_message = `<div class='text-danger px-5'> \
                              <ul> <li> ${error} </li></ul>\
                          </div>`;
    error_message_div.append(error_message);
  }
}

const displayWarningMessage = (warning_message, warning_message_div) => {
  const show_warning_message = `<div class='alert alert-warning w-100'><h6 class='pt-2'> ${warning_message}</h6>`
                                  "</div>";
    warning_message_div.removeClass('d-none')
    warning_message_div.html(show_warning_message);
    $('.merge_request').val(true)
}

const displaySuccessMessage = (message, modal) => {
  $('.alert-container').removeClass('d-none');
    const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                </span><strong> ${message} </strong>\
                                <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                                </button> \
                                </p>`;
    const success_message = `<div class='alert alert-success w-100'>\
                            <h6> ${header_success_message} </h6>`
    "</div>";
    $('.alert-container').html(success_message);
    modal.find('form').trigger('reset');
    modal.modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
      $('.alert-container').slideUp(1000);
    });
}

const removeErrorAndWarningMessage = (modal, message_div) => {
  $(document).on('hidden.bs.modal', `${modal}`, () => {
    if(!message_div.hasClass('d-none')) {
      message_div.addClass('d-none')
    }
    $('.merge_request').val(false)
  })
}

$(document).on('change', '#activity_related_to', (e) =>{
  selected_value = $(e.target).val();
  if (selected_value == 'Allowances'){
    hide_and_clear_dropdown($('#claim_types_dropdown'));
    show_dropdown($('#allowances_dropdown'));
    $('#apply-analytics-filter, #apply-data_insight_filters').prop('disabled', true);
  }
  else if(selected_value == 'Claims'){
    hide_and_clear_dropdown($('#allowances_dropdown'));
    show_dropdown($('#claim_types_dropdown'));
    $('#apply-analytics-filter, #apply-data_insight_filters').prop('disabled', true);
  }
  else {
    hide_and_clear_dropdown($('#allowances_dropdown'));
    hide_and_clear_dropdown($('#claim_types_dropdown'));
    $('#apply-analytics-filter, #apply-data_insight_filters').prop('disabled', false);
  }
});

$(document).on('change', '#allowances_dropdown, #claim_types_dropdown', (e) => {
  let dropdown = $(e.target)
  if (dropdown.is(':visible') && dropdown.val() == ''){
    $('#apply-analytics-filter, #apply-data_insight_filters').prop('disabled', true);
  }
  else {
    $('#apply-analytics-filter, #apply-data_insight_filters').prop('disabled', false);
  }
});

$(document).on('reset', '#employee_analytics_chart_filters', (e) =>{
  hide_and_clear_dropdown($('#allowances_dropdown'));
  hide_and_clear_dropdown($('#claim_types_dropdown'));
  $(".custom-month-range-input").attr("readonly", true);
  $('#activity_related_to').val('Net Salary')
  display_employee_analytics();
})

$(document).on('change', '#custom_month_range_picker_template', (e) => {
  initialize_custom_month_range($(e.target), '#custom_month_range_picker_template')
});

$(document).on('change', '.pf_history_month_range', (e) => {
  initialize_custom_month_range($(e.target), '.pf_history_month_range');
  render_employee_pf_history_table(e.target.closest('#custom_month_range_picker_container').getAttribute('employee_id'));
});

$(document).on('dp.update', '.custom-month-range-input', (e) => {
  $("#analytics_from").data("DateTimePicker").maxDate($("#analytics_till").val());
  $("#analytics_till").data("DateTimePicker").minDate($("#analytics_from").val());

  let datepicker_container = e.target.closest('#custom_month_range_picker_container');
  if(datepicker_container.getAttribute('show-pf-history') === 'true') {
    render_employee_pf_history_table(datepicker_container.getAttribute('employee_id'));
  }
});

$(document).on('draw.dt', '#profile-claim-table', () => {
  let wrapper_width = $('#profile-claim-table_wrapper').find('.dataTables_scrollBody').width()
  $('#profile-claim-table').width(wrapper_width)
});

const show_dropdown = (dropdown) => {
  container = dropdown.parent().closest('div')
  container.removeClass('d-none')
}

const display_employee_analytics = () => {
  $('#apply-analytics-filter').click();
}

const set_custom_month_range = (currentSalaryMonth, range, dropdown_id) => {
  var rangeStart, rangeEnd
  switch(range){
    case "Current Month":
      rangeStart = moment(currentSalaryMonth).startOf('month')
      rangeEnd = moment(currentSalaryMonth).endOf('month')
      break
    case "Previous Month":
      rangeStart = moment(currentSalaryMonth).subtract(1, 'month').startOf('month')
      rangeEnd = moment(currentSalaryMonth).subtract(1, 'month').endOf('month')
      break
    case "Last 3 Months":
      rangeStart = moment(currentSalaryMonth).subtract(3, 'month').startOf('month')
      rangeEnd = moment(currentSalaryMonth).subtract(1, 'month').endOf('month')
      break
    case "Last 6 Months":
      rangeStart = moment(currentSalaryMonth).subtract(6, 'month').startOf('month')
      rangeEnd = moment(currentSalaryMonth).subtract(1, 'month').endOf('month')
      break
    case "Last 12 Months":
      rangeStart = moment(currentSalaryMonth).subtract(1, 'year').startOf('month')
      rangeEnd = moment(currentSalaryMonth).subtract(1, 'month').endOf('month')
      break
    case "Current Fiscal Year to Date":
      if( moment(currentSalaryMonth).quarter() < 3 ){
        rangeStart = moment(currentSalaryMonth).subtract(1, 'year').month('July').startOf('month')
      }
      else {
        rangeStart = moment(currentSalaryMonth).month('July').startOf('month')
      }
      rangeEnd = moment(currentSalaryMonth).endOf('month')
      break
    case "Previous Fiscal Year":
      if( moment(currentSalaryMonth).quarter() < 3 ){
        rangeStart = moment(currentSalaryMonth).subtract(2, 'year').month('July').startOf('month')
        rangeEnd = moment(currentSalaryMonth).subtract(1, 'year').month('June').endOf('month')
      }
      else {
        rangeStart = moment(currentSalaryMonth).subtract(1, 'year').month('July').startOf('month')
        rangeEnd = moment(currentSalaryMonth).month('June').endOf('month')
      }
      break
    default:
      rangeStart = moment(currentSalaryMonth).startOf('month')
      rangeEnd = moment(currentSalaryMonth).endOf('month')
  }

  from_field_id = dropdown_id === '.pf_history_month_range' ? '#pf_history_from' : '#analytics_from'
  till_field_id = dropdown_id === '.pf_history_month_range' ? '#pf_history_till' : '#analytics_till'

  $(from_field_id).val(rangeStart.format('MMM, YYYY'))
  $(till_field_id).val(rangeEnd.format('MMM, YYYY'))
}

const initialize_custom_month_range = (dropdown, dropdown_id) => {
  selected_value = dropdown.val();
  currentSalaryMonth = $(dropdown_id).closest('#custom_month_range_picker_container').attr('data-current-salary-month')
  from_field_id = dropdown_id === '.pf_history_month_range' ? '#pf_history_from' : '#analytics_from'
  if (selected_value == 'Custom Select'){
    $(".custom-month-range-input").attr("readonly", false);
    $(from_field_id).datetimepicker("show");
  }
  else{
    $(".custom-month-range-input").attr("readonly", true);
    set_custom_month_range(currentSalaryMonth, selected_value, dropdown_id)
  }
}
